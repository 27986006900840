/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'qRix7dch53ddfGqa4i7BFN.png': { uri: '/images/qRix7dch53ddfGqa4i7BFN.png' },
'7e3eoZzgWBtPBXCMSr2BP1.png': { uri: '/images/7e3eoZzgWBtPBXCMSr2BP1.png' },
'eAMGSVN9qSjqFhuKqmPke3.webp': { uri: '/images/eAMGSVN9qSjqFhuKqmPke3.webp' },
'1fcc3aWz6BEUanwmgRYPsd.png': { uri: '/images/1fcc3aWz6BEUanwmgRYPsd.png' },
'k1LNfdLbXz2LaquwQLic9J.png': { uri: '/images/k1LNfdLbXz2LaquwQLic9J.png' },
'eTWxLTgtnv9B5FWHogyfDw.png': { uri: '/images/eTWxLTgtnv9B5FWHogyfDw.png' },
'4EPffs9frRk4TE2g1Tudvi.png': { uri: '/images/4EPffs9frRk4TE2g1Tudvi.png' },
'1EyRtQv2MsRqWsJBMiCRHg.png': { uri: '/images/1EyRtQv2MsRqWsJBMiCRHg.png' },
'dRmTb7LBE7n8LJkH3Rb6Lk.png': { uri: '/images/dRmTb7LBE7n8LJkH3Rb6Lk.png' },
'ce7LzDhdyvxRT3NdqtDfBc.png': { uri: '/images/ce7LzDhdyvxRT3NdqtDfBc.png' },
'sn44kpbfQS33ea1xoSXz2Z.png': { uri: '/images/sn44kpbfQS33ea1xoSXz2Z.png' },
'3pJK3UGpRcEBvjpfBqCa6w.png': { uri: '/images/3pJK3UGpRcEBvjpfBqCa6w.png' },
'rCcsyAvtRfkyXyEWbqB5bY.png': { uri: '/images/rCcsyAvtRfkyXyEWbqB5bY.png' },
'8UdDwF8CkrkDeTbe1Hb9A8.png': { uri: '/images/8UdDwF8CkrkDeTbe1Hb9A8.png' },
'i1oX6sHuuKWuqUJtfkhrCZ.png': { uri: '/images/i1oX6sHuuKWuqUJtfkhrCZ.png' },
'cV4Su9DYvvyngt2H3c1Fd2.png': { uri: '/images/cV4Su9DYvvyngt2H3c1Fd2.png' },
'9KfuUtEGTTqpoRwrBztpqg.png': { uri: '/images/9KfuUtEGTTqpoRwrBztpqg.png' },
'jsAFzNvPLGsCoVH7stowUx.png': { uri: '/images/jsAFzNvPLGsCoVH7stowUx.png' },
'ag5W83vYoNhzCJSdsmHv3G.png': { uri: '/images/ag5W83vYoNhzCJSdsmHv3G.png' },
'tyFf4pgu8adkX4yVkxeGE3.png': { uri: '/images/tyFf4pgu8adkX4yVkxeGE3.png' },
'foGKNQpqmdMW8UbeLsFwdk.png': { uri: '/images/foGKNQpqmdMW8UbeLsFwdk.png' },
'as1NK5Tg2XEW8TRuyqUWRt.png': { uri: '/images/as1NK5Tg2XEW8TRuyqUWRt.png' },
'tNzaiBBXf5ra85Va19xcBY.png': { uri: '/images/tNzaiBBXf5ra85Va19xcBY.png' },
'qZFTcc3bXfpDYEduTV19iz.png': { uri: '/images/qZFTcc3bXfpDYEduTV19iz.png' },
'pEah8tqVUAUjfga5XSYdAm.png': { uri: '/images/pEah8tqVUAUjfga5XSYdAm.png' },
'pa5Jp6t3gX18jETJTJ7jFs.png': { uri: '/images/pa5Jp6t3gX18jETJTJ7jFs.png' },
'8thQqbkFXPqGVFhUjDYMsi.png': { uri: '/images/8thQqbkFXPqGVFhUjDYMsi.png' },
'trxFW15tT1Teq6JvVDkJhh.png': { uri: '/images/trxFW15tT1Teq6JvVDkJhh.png' },
'52zKSaKBt8vCHsLx13sh5C.png': { uri: '/images/52zKSaKBt8vCHsLx13sh5C.png' },
'6iXJeXLmDVDYspe7XgcyEW.png': { uri: '/images/6iXJeXLmDVDYspe7XgcyEW.png' },
'jpTBAhQyBBTEBPtCwXpQQM.png': { uri: '/images/jpTBAhQyBBTEBPtCwXpQQM.png' },
'3tMQypZ6nkJvgFQ9uwQYLH.png': { uri: '/images/3tMQypZ6nkJvgFQ9uwQYLH.png' },
'xq1uwKW11mA3JjuC3imQRp.png': { uri: '/images/xq1uwKW11mA3JjuC3imQRp.png' },
'27ikxcMtB3vx7ZuDE8fVbE.png': { uri: '/images/27ikxcMtB3vx7ZuDE8fVbE.png' },
'uHxuKUWKV3CkwRf7cPcnDX.png': { uri: '/images/uHxuKUWKV3CkwRf7cPcnDX.png' },
'rWmASrCAPdTprhB6Xsa7Yf.png': { uri: '/images/rWmASrCAPdTprhB6Xsa7Yf.png' },
'w14K3RPqDWe7qEuqf8DqXf.png': { uri: '/images/w14K3RPqDWe7qEuqf8DqXf.png' },
'5EKX4bckN3GbkvWFNJSTqS.png': { uri: '/images/5EKX4bckN3GbkvWFNJSTqS.png' },
'3FHQaBQHfUSHESKAoHLZNM.png': { uri: '/images/3FHQaBQHfUSHESKAoHLZNM.png' },
'xvYHQeosoPQUUcNzDAcUVq.png': { uri: '/images/xvYHQeosoPQUUcNzDAcUVq.png' },
'xzRUGjhDawoCisc2MYgE3C.png': { uri: '/images/xzRUGjhDawoCisc2MYgE3C.png' },
'35LbXQ2wSWLntPyiZ76Bts.png': { uri: '/images/35LbXQ2wSWLntPyiZ76Bts.png' },
'e4GLjaFTWVBJcp7u9gm1cK.png': { uri: '/images/e4GLjaFTWVBJcp7u9gm1cK.png' },
'vvLXFe5TNrDHCAerfSx7Hr.png': { uri: '/images/vvLXFe5TNrDHCAerfSx7Hr.png' },
'dA8T93RqecEpf8kAEBEPat.png': { uri: '/images/dA8T93RqecEpf8kAEBEPat.png' },
'bb2MYvQWSx65qJdEZuK6va.png': { uri: '/images/bb2MYvQWSx65qJdEZuK6va.png' },
'bnCs3ZxCkPU2VYRLhzAxbh.png': { uri: '/images/bnCs3ZxCkPU2VYRLhzAxbh.png' },
'gh8CQTxJCoH7CeNQjxBwXL.png': { uri: '/images/gh8CQTxJCoH7CeNQjxBwXL.png' },
'32w9sPoYWDR5fDzJ2P4gf1.png': { uri: '/images/32w9sPoYWDR5fDzJ2P4gf1.png' },
'f1oNRcaSRmQeVAqrirMSPE.jpg': { uri: '/images/f1oNRcaSRmQeVAqrirMSPE.jpg' },
'azqCpsRBrHhYTNz2dE3yFU.png': { uri: '/images/azqCpsRBrHhYTNz2dE3yFU.png' },
'x9VAsWSp2kPdNxZa9fozK9.webp': { uri: '/images/x9VAsWSp2kPdNxZa9fozK9.webp' },
'fLGs8vxASXWJ7k8AmPZD39.png': { uri: '/images/fLGs8vxASXWJ7k8AmPZD39.png' },
'j6H7tR8LfwheGz2PDfKF14.png': { uri: '/images/j6H7tR8LfwheGz2PDfKF14.png' },
'moi4sPo6uwWQSHt5HqnxoX.jpg': { uri: '/images/moi4sPo6uwWQSHt5HqnxoX.jpg' },
'3GKpVyKBjHUrPaFsj1QU71.jpg': { uri: '/images/3GKpVyKBjHUrPaFsj1QU71.jpg' },
'1rEdFoVWq4zgFPmhsuFJrs.jpg': { uri: '/images/1rEdFoVWq4zgFPmhsuFJrs.jpg' },
'jtHu6Gur27Gw8B4xgz42jx.png': { uri: '/images/jtHu6Gur27Gw8B4xgz42jx.png' },
'ty4rWPMfg86tnDSVHn5AyL.jpg': { uri: '/images/ty4rWPMfg86tnDSVHn5AyL.jpg' },
'rcTKRbxh65v9Hr9Rm64zpQ.jpg': { uri: '/images/rcTKRbxh65v9Hr9Rm64zpQ.jpg' },
'jGRFhQKwQehCWcAg8Y2tyS.png': { uri: '/images/jGRFhQKwQehCWcAg8Y2tyS.png' },
'jfeuRoRaJKTHtyU7kKQ5yd.jpg': { uri: '/images/jfeuRoRaJKTHtyU7kKQ5yd.jpg' },
'hXrU77YEt2bWeYMvca4gbW.jpg': { uri: '/images/hXrU77YEt2bWeYMvca4gbW.jpg' },
'a4ritMbP4tjR4ju8exQKdC.jpg': { uri: '/images/a4ritMbP4tjR4ju8exQKdC.jpg' },
'hCdva8eJYQST5Fj6RoiY9Q.png': { uri: '/images/hCdva8eJYQST5Fj6RoiY9Q.png' },
'fbB2Vb13vEv4YyCA2SCZ94.png': { uri: '/images/fbB2Vb13vEv4YyCA2SCZ94.png' },
'bzEc9iVwAqNvJEBajWRv8B.png': { uri: '/images/bzEc9iVwAqNvJEBajWRv8B.png' },
'jHZXiwApnhBw3YF42RqYCJ.jpg': { uri: '/images/jHZXiwApnhBw3YF42RqYCJ.jpg' },
'dwkDqhPi3141SveDuCXMXx.png': { uri: '/images/dwkDqhPi3141SveDuCXMXx.png' }
}

export default imageStaticSourcesByFileName
